<template>
	<el-dialog title="插入图片" width="31.8%" :visible.sync="dialogVisible" :before-close="()=>$emit('update:close',false)">
		<el-tabs v-model="activeName">
			<el-tab-pane label="我的相册" name="1" :disabled="isDisabled">
				<div class="album-ref">
					<div class="album-label">
						<span>选择相册:</span>
						<el-select v-model="form.album_id" @change="handleChangeAlbum">
							<el-option v-for="item in albumData.list" :key="item.id" :label="item.title" :value="item.id" />
						</el-select>
					</div>
					<ul class="img-list" v-show="imgList.length">
						<li v-for="item in imgList" :key="item.pic_id" @click="imageList.push(item.thumb)">
							<img :src="$store.state.IMG_URL+'/'+item.thumb" alt="">
						</li>
					</ul>
					<div class="img-null" v-show="!imgList.length">该相册下暂无图片~</div>
					<div class="pages">
						<el-pagination layout="prev, pager, next" :total="count" hide-on-single-page
							:current-page="page" @current-change="currentChange" />
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="本地上传" name="2">
				<div class="form">
					<div class="form-item">
						<p class="form-item-label">选择相册：</p>
						<el-select size="mini" v-model="form.album_id" @change="handleChangeAlbum">
							<el-option v-for="item in albumData.list" :key="item.id" :label="item.title"
								:value="item.id" />
						</el-select>
						<p class="form-item-tip">或
							<el-popover v-model="buildVisible">
								<div class="build-album">
									<span>相册名称：</span>
									<el-input placeholder="请输入相册名称" size="mini" v-model="buildValue" />
								</div>
								<div class="build-btn">
									<el-button type="primary" size="mini" @click="handleAddAlbum">确定</el-button>
									<el-button size="mini" @click="buildVisible=false">取消</el-button>
								</div>
								<span slot="reference">新建相册</span>
							</el-popover>
						</p>
					</div>
					<div class="form-item">
						<p class="form-item-label">添加水印：</p>
						<el-switch v-model="isMark" size="mini" :active-value="1" :inactive-value="0" />
						<p class="form-item-tip">水印内容默认为您的公司名称</p>
					</div>
					<div class="form-item">
						<p class="form-item-label">上传图片：</p>
						<div class="file-btn">选择图片并上传<input type="file" multiple @change="handleFileImage"
								accept="image/png,image/jpeg" ref="fileInput" /></div>
					</div>
					<div class="form-item form-item-footer">
						<p class="form-item-label">注：</p>
						<div class="form-item-footer-text">
							<p>1. 您当前总共可添加 <i>10</i> 个相册，总共可上传 <i>4000</i> 张图片</p>
							<p>2. 当上传的图片宽度超过 <i>1000</i> 像素时，系统会自动对图片进行等比例压缩</p>
							<p>3. 图片支持jpg、jpeg、png格式，单张大小不超过3M</p>
							<p>4. <i>请勿上传、使用带有任何联系方式（如手机、QQ、电话、微信号及微信二维码等）的图片</i></p>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<ul class="image-list" v-loading="imgLoading">
			<li v-for="item,index in imageList"  :data_id="index" >
				<span class="el-icon-delete del" @click="removeImg(index)"  :imgId="index"></span>
				<img :src="$store.state.IMG_URL + '/'+item" alt="">
			</li>
		</ul>
		<span slot="footer" class="dialog-footer">
			<el-button size="mini" @click="()=>$emit('update:close',false)">取 消</el-button>
			<el-button type="primary" @click="handleFinish" size="mini">完 成</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import Axios from 'axios'
	import $ from 'jquery';
	export default {
		data() {
			return {
				form: {
					album_id: 0,
					thumb: [],
					title: []
				},
				isMark: 1,
				buildValue: '',
				buildVisible: false,
				imageList: [],
				imgLoading: false,
				dialogVisible: false,
				isDisabled: false,
				imgList: [],
				count: 0,
				page: 1,
				// albumData: {
				// 	list: [{
				// 		id: 0
				// 	}]
				// },
				albumData: {
					list: []
				},
				newAlbum:{}
			}
		},
		created() {
			this.getlist()
			
		},
		methods: {
			getlist(){
				this.$axios.post('/albumList').then(data => {
					this.albumData = data
					this.form.album_id = this.echoId ? this.echoId : data.list[0].id
					this.getPic()
				})
			},
			handleAddAlbum() {
				if(this.albumData.list.length < 10){
					if (!this.buildValue) {
						this.$message.error('请填写名称')
						return
					}
					this.$axios.post('/addAlbum', {
						title: this.buildValue,
					}).then(data => {
						if (data.code == 41002) {
							this.$message.error('添加失败')
							return
						}
						if (data.code == 41001) {
							this.$message.error('添加失败')
							return
						}
						this.albumData.list.push({
							id: data.id,
							title: this.buildValue
						})
						this.$message.success('添加成功')
						this.buildVisible = false
						this.form.album_id = data.id
						this.buildValue = ''
						this.imgList = ''
						this.count = ""
						this.getPic()
					})
					
				}else{
					this.$alert('您最多创建10个相册', '提示', {
						  confirmButtonText: '确定',
						  callback: action => {
							  
						  }
					});
				}
			},
			async handleFileImage() {
				if(this.albumData.list.length >0 ){
					const fileInput = this.$refs.fileInput
					let reader = '',
						imgForm = ''
					if (fileInput.value) {
						if (fileInput.files.length > 20) {
							this.$message.warning('上传图片达到上限，单次最多上传20张图片~')
							return
						}
						this.imgLoading = true
						for (let i = 0; i < fileInput.files.length; i++) {
							var ind =fileInput.files[i].index;
							if(fileInput.files[i].size / 1024/1024 <3){
								imgForm = new FormData()
								imgForm.append('file', fileInput.files[i])
								imgForm.append('type', 'Goods')
								imgForm.append('is_water', this.isMark)
								await this.postImage(imgForm).then(data => {
									if (data.code == 200) {
										this.imageList.push(data.data.src)
										this.form.thumb.push(data.data.src)
										this.form.title.push(data.data.title)
										reader = new FileReader()
										reader.readAsDataURL(fileInput.files[i])
									}
								})
							}else{
								// this.$message.error('第'+i+'张图片较大,换一张把~')
								this.$message.error('图片较大,换一张把~')
							}
						}
						this.imgLoading = false
					}
				}else{
					this.$message.warning('至少新建一个相册~')
				}
				
			},
			postImage(data) {
				return new Promise((resolve, reject) => {
					Axios.post(this.$store.state.BASE_URL + '/uploadImgOne', data, {
						timeout: 20000
					}).then(res => {
						if(res.data.code == 200){
							resolve(res.data)	
						}else{
							resolve(this.imgLoading = false)
							this.$message.error(res.data.msg)
						}
						
						
					}).catch(err => {
						this.$alert('上传失败，请联系客服或重试', '提示', {
							type: 'error'
						})
						this.imgLoading = false
					})
				})
			},
			removeImg(ind){
				 this.imageList.splice(ind,1)
			},
			handleFinish() {
				if (this.form.thumb.length) {
					this.$axios.post('/submitAll', this.form).then(data => {
						if(data.code == 200){
							this.$message.success('上传成功')
						}else{
							this.$message.error(data.msg)
						}
						
					})
				}
				this.$emit('update:close', false)
				this.$emit('eventSubmit', this.imageList)
			},
			handleChangeAlbum(val) {
				this.page = 1
				this.form.album_id = val
				this.getPic()
			},
			getPic() {
				this.$axios.post('/albumPicList', {
					album_id: this.form.album_id,
					page: this.page,
					limit: 10
				}, {
					loading: 'no'
				}).then(data => {
					if (data.code == 41002) {
						this.$message.error('获取失败')
						return
					}
					this.imgList = data.list
					this.count = data.count
				})
			},
			currentChange(cur) {
				this.page = cur
				this.getPic()
			}
		},
		computed: {
			activeName: {
				get() {
					if (this.active == 2) {
						this.isDisabled = true
					}
					return this.active
				},
				set(val) {
					return val
				}
			},
		},
		watch: {
			albumData(val){
				this.newAlbum = val
				this.albumData = val
			},
			echoId(val) {
				this.form.album_id = val
			},
			show(val) {
				this.dialogVisible = val
				this.form.thumb = []
				this.form.title = []
				
				this.imageList = JSON.parse(JSON.stringify(this.initImg))
				this.getlist()
			}
		},
		props: {
			echoId: {
				default: 0
			},
			show: {
				default: false
			},
			active: {
				default: '1'
			},
			initImg: {
				default () {
					return []
				}
			},
			 albumData: {
			 	default () {
					return []
				}
			 },
		}
	}
</script>

<style scoped>
	.form-item {
		font-size: 12px;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}

	.form-item-label {
		width: 70px;
		text-align: right;
	}

	.form-item-tip {
		margin-left: 10px;
		color: #909399;
	}

	.form-item-tip span {
		color: #0f69dc;
		margin-left: 5px;
		cursor: pointer;
	}

	.file-btn {
		position: relative;
		padding: 0 15px;
		background: #0f69dc;
		color: #fff;
		height: 30px;
		line-height: 30px;
		cursor: pointer;
		border-radius: 5px;
	}

	.file-btn input {
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
	}

	.form-item-footer {
		align-items: flex-start;
		line-height: 20px;
	}

	.form-item-footer-text i {
		color: #e60000;
		font-style: normal;
	}

	.build-album {
		display: flex;
		align-items: center;
		font-size: 12px;
		margin-top: 10px;
	}

	.build-album>span {
		white-space: nowrap;
	}

	.build-btn {
		width: 60%;
		margin: 10px auto 0;
		display: flex;
		justify-content: space-between;
	}

	.image-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -10px;
	}

	.image-list li {
		width: 65px;
		height: 65px;
		border: 1px solid #e7e6e4;
		margin: 10px 0 0 10px;
		position: relative;
	}

	.image-list img {
		width: 100%;
		height: 100%;
	}

	.album-label {
		font-size: 12px;
		display: flex;
		align-items: center;
	}

	.album-label>span {
		margin-right: 10px;
	}

	.img-list {
		display: flex;
		flex-wrap: wrap;
		margin: 20px 0 15px -10px;
	}

	.img-list li {
		width: 102px;
		height: 102px;
		border: 1px solid #ccc;
		margin: 0 0 10px 10px;
	}

	.img-list img {
		width: 102px;
		height: 102px;
	}

	.pages {
		text-align: center;
	}

	.img-null {
		padding: 30px 0;
		text-align: center;
	}
	.del{
		position: absolute;
		right: 0;top: 0;
	}
</style>
